<template>
    <div class="row">
        <div class="questionList col-3">
            <b-list-group >
                <b-list-group-item  v-for="(question,index) in questionLists" :key="question.question_type_cde" class="d-flex justify-content-between align-items-center" @click="getQuestionDetail(question['question_type_cde'])" >
                    {{question["question_type_desc"]}}
                    <!-- Used this for user attempts -->
                    <b-badge variant="primary" pill>{{question['quest'+index]}}</b-badge>
                </b-list-group-item>
            </b-list-group>
        </div>
        <div class="questiondesc col-9" v-if="selectedquestion">
             <app-single v-if="selectedquestion=='8'"/>
             <app-multi v-if="selectedquestion=='9'"/>
             <app-reOderPara v-if="selectedquestion=='10'"/>
             <app-fillinblank v-if="selectedquestion=='11'"/>
             <app-fillinblankrw v-if="selectedquestion=='12'"/>
        </div>
    </div>
</template>
<script>
import { mapActions,mapGetters } from 'vuex'
import multipleChoiceSingle from './multipleChoiceSingle'
import multipleChoiceMulit from './multipleChoiceMulit'
import reOrderPara from './reOrderPara'
import Fillinblank from './fillinblank.vue'
import Fillinblankrw from './fillinblankR&D'
export default {
    components:{
        'app-single':multipleChoiceSingle,
        'app-multi':multipleChoiceMulit,
        'app-reOderPara':reOrderPara,
        'app-fillinblank':Fillinblank,
        'app-fillinblankrw':Fillinblankrw
    },
    data(){
        return{
            questionLists:{
                type: Object,
                required: true
            },
            selectedquestion: null
        }
    },
    methods:{
        ...mapActions({
            questionservice: 'createquestion/fetchQuestion'
        }),
        ...mapGetters({
            getQuestionList: 'createquestion/questions'
        }),
        getQuestionDetail(questionId){
            this.selectedquestion = questionId;
            ////console.log(this.selectedquestion)
        },
        getQuestionTypes(){
            this.questionservice('getquestiontypes/getreadingmodules').then(()=>{
            this.questionLists = this.getQuestionList().data
            ////console.log(this.questionLists)
        })
        }
    },
    beforeMount(){
        this.getQuestionTypes()
    }
}
</script>
<style scoped>

</style>
<style>
.btn-light, .btn-outline-danger
{

/* padding: 3px 7px!important; */
    border: 1px solid #ced4da!important;
    color: rgb(94, 114, 228);
    /* margin-left: 5px; */
    /* margin-top: 1px; */
    /* margin-bottom: 1px; */
    background: #fff!important;
}
/* .btn-outline-danger, .btn-outline-danger:hover, .btn-outline-danger:active, .btn-outline-danger:focus
{
    color:#dc3545!important;
    background: #fff!important;
} */
.btn-light:hover, .btn-light:active, .btn-light:focus
{
    color: rgb(94, 114, 228);
}
.btn-light:not(:disabled)
{
    border: 1px solid #5e72e4 !important;
}
.btn-outline-danger:not(:disabled)
{
    border: 1px solid #dc3545 !important;
}
.btn-light:not(:disabled)
{
    /* border-left: 0px!important; */
    border-top-left-radius: 0px!important;
    border-bottom-left-radius: 0px!important;
}
.extraNote
{
    color:#ccc;
}
button.btn-outline-warning, button.btn-outline-success
{
    font-size: .8rem;
    font-size: 1rem;
    padding: 0.375rem 0.5rem;
}
</style>