<template>
    <div>
        <b-row>
            <b-col cols="10">
                    <h4 v-if="checkFlag">Please configure reorder paragraph question.</h4>
                    <h4 v-else>Please select excel sheet for bulk question saving</h4>
                </b-col>
                <b-col cols="2">
                    <toggle-button :width="75" :height="28" v-model="checkFlag" :value="false" :color="{checked: '#104e94', unchecked: '#e9ecef', disabled: '#CCCCCC'}" :speed="500" :sync="true" :labels="{checked: 'enter', unchecked: 'load'}"/>
                </b-col>
            <b-form v-if="checkFlag" class="col-12"  @submit="onSubmit" @reset="onReset">
            <b-row>
                <b-col cols="6">
                    <label for="title">Enter Title</label>
                    <b-form-input v-model="form.title" placeholder="Enter Question Title"></b-form-input>
                </b-col>
                <b-col cols="6">
                    <label for="mock_ind">Select Question Type</label>
                    <b-form-select v-model="form.mock_ind" :options="dropdown"></b-form-select>
                </b-col>
            </b-row>
            <b-col cols="12 mt-3">
                <h5>Add Options</h5>
                <b-row>
                    <div class="col-1" style="color:red">Incorrect</div>
                    <div class="col-10" style="text-align:center">Paragraph</div>
                    <div class="col-1" style="color:green">Correct</div>
                </b-row>
               <b-row  v-for="(ct,index) in reOrderPara" :key="index" class="mt-4">
                   <div class="col-1" style="text-align:center">
                       <span><strong>({{index+1}})</strong></span>
                   </div>
                    <div class="input-group col-10">
                        <textarea v-model="ct.reorder_desc" class="form-control" id="reorder_desc" rows="3"></textarea>
                        <!-- <input v-model="ct.optionText" id="optionText" type="text" class="form-control shadow-sm" name="optionText" placeholder="Option Text"> -->
                        <b-button :disabled="ct.reorder_desc ==''" v-if="ct.add" variant="light" @click="addOption(ct)">
                            <b-icon shift-v="1" scale="1.1" icon="plus" style="color: #5e72e4;"></b-icon>
                            Add
                        </b-button>
                        <b-button v-else variant="outline-danger" @click="removeOption(index)">
                            <b-icon shift-v="1" scale=".8" icon="dash-circle" variant="danger"></b-icon>
                        </b-button>
                    </div>
                    <div class="col-1">
                        <input v-model="ct.order_seq" type="text" class="form-control shadow-sm">
                    </div>
                </b-row>
            </b-col>
                <div class="col-6 displayInline" align="right">
                    <!--  :disabled="allFilled != true" -->
                    <b-button type="submit" variant="primary">{{buttonText}}</b-button>
                </div>
                <div class="col-6 displayInline" align="left">
                    <b-button type="reset" variant="outline-danger">Reset</b-button>
                </div>
            </b-form>
        </b-row>
        <b-row v-if="checkFlag" class="mt-4 ml-2 mr-2">
            <b-col cols="12">
                <b-alert
                :show="dismissCountDown"
                dismissible
                variant="outline-success"
                @dismissed="dismissCountDown=0"
                
                >
                Record saved successfully, dismiss in {{ dismissCountDown }} seconds...
                </b-alert>
            </b-col>
        </b-row>
        <b-row v-if="checkFlag" class="mt-4 ml-2 mr-2">
            <b-col cols="12">
                <b-table
                    id="multipleChoiceSingle"
                    :items="allQuestions"
                    :per-page="perPage"
                    :current-page="currentPage"
                    :fields="fields"
                    sort-icon-left
                    small
                    outlined
                    responsive
                    >
                    <template #cell(question_id)="data">
                    <!-- `data.value` is the value after formatted by the Formatter -->
                    <b-button variant="outline-warning" @click="deleteRecord(data.value)">
                        <b-icon icon="trash-fill"></b-icon>
                    </b-button>
                    <!-- <a href="#">{{data.value}}</a> -->
                </template>
                </b-table>
            </b-col>
            <b-col cols="12">
                <b-pagination
                    v-model="currentPage"
                    :total-rows="rows"
                    :per-page="perPage"
                    aria-controls="multipleChoiceSingle"
                    ></b-pagination>
            </b-col>
        </b-row>
        <div v-if="!checkFlag" class="row mt-4 ml-2 mr-2">
            <div class="col-12">
                
                <div class="form-group">
                    <label for="excelFile">Select Excel file</label>
                    <b-form-file
                        id="excelFile"
                        placeholder="Choose a file or drop it here..."
                        drop-placeholder="Drop file here..."
                        accept=".xlsx"
                        @change="onFileChange"></b-form-file>
                </div>
            </div>
            <div class="col-12 text-center">
                <b-button :disabled="!fileLoad" @click="readFile()" class="mr-4">Read File</b-button>
                <b-button :disabled="!readFileFlag" variant="primary" @click="saveRecordExcel(1)">{{saveRecord}}</b-button>
            </div>
        </div>
        <div v-if="!checkFlag" class="row mt-4 ml-2 mr-2">
            <div class="col-12">
            <ul id="console-textarea" >
                <li v-for="(item,index) in consoleLog" :key="index">
                    {{item}}
                </li>
            </ul>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions,mapGetters } from 'vuex'
import readXlsxFile from 'read-excel-file'
export default {
    data(){
        return{
            buttonText:'Save',
            allFilled: false,
             dismissSecs: 5,
            dismissCountDown: 0,
            excelFile:null,
            fileLoad:false,
            checkFlag:true,
            readFileFlag:false,
            rowsLoadedArray:null,
            checkArray:[0],
            saveRecord:'Save Record',
            consoleLog:['Log Info.'],
            form:{
                title:'',
                slot_time:'00:40',
                url:'',
                answers:null,
                mock_ind:'F'
            },
            reOrderPara:[{
                reorder_id:0,
                order_seq:0,
                reorder_desc:'',
                add:true
            }],
            dropdown: [
                { value: '0', text: 'Please select an option' },
                { value: 'F', text: 'General' },
                { value: 'T', text: 'Mock Test' }
            ],
            allQuestions:null,
            perPage: 8,
            currentPage: 1,
            fields: [
            // {
            //     key: 'edit',
            //     label:'',
            //     sortable: false
            // },
            {
                key: 'question_id',
                label:'ID',
                sortable: true
            },
            {
                key: 'title',
                label:'Title',
                sortable: false
            }
            ]
        }
    },
    methods:{
        ...mapActions({
            saveAnswer: 'readingmodule/saveAnswer',
            saveQuestion:'readingmodule/saveQuestion',
            questionservice: 'readingmodule/fetchQuestion',
            deleteQuestion:'readingmodule/deleteQuestion'
        }),
        ...mapGetters({
            answerResponse: 'readingmodule/AnswerResponse',
            getQuestionList: 'readingmodule/questions'
        }),
        onFileChange(e) {
            const file = e.target.files[0];
            this.excelFile=file
            this.fileLoad =true
            this.readFileFlag=false
            this.consoleLog.push('Selected file loaded.')
            // this.loadedFile = e.target.files
            // this.fileUrl = URL.createObjectURL(file);
        },
        readFile(){
            if(this.excelFile!=null){
            readXlsxFile(this.excelFile).then((rows) => {
                            //console.log(rows)
                            this.consoleLog.push('Total records retrieve from excel sheet ' +rows.length +'.')
                            this.fileLoad =false
                            this.readFileFlag=true
                            this.rowsLoadedArray=rows
                        })
            }
        },
        saveRecordExcel(index){
            if(this.rowsLoadedArray==null)
            {
                return this.$alert('No record found')
            }
            else{
                let today = new Date().toLocaleDateString()
                var item=this.rowsLoadedArray[index]
                ////console.log(item)
                var filterArray=this.checkArray.find(e => e === item[0])
                if(filterArray==null){
                    this.checkArray.push(item[0])
                    //console.log(this.checkArray)
                    this.reOrderPara=[]
                    this.rowsLoadedArray.forEach(element => {
                        if(element[0]== item[0])
                        {
                        const tempCt= {
                            reorder_id:element[2],
                            order_seq:element[3],
                            reorder_desc:(element[4]!=null)?element[4].replace(/[^a-zA-Z ]/g, ""):'',
                            add:true
                        }
                        this.reOrderPara.push(tempCt)
                    }
                    });
                    this.form={
                    title:(item[1]!=null)?item[1].replace(/[^a-zA-Z ]/g, ""):'',
                    slot_time:today,
                    url:'configreorderpara/createparagraph',
                    answers:this.reOrderPara,
                    mock_ind:'F',
                    new_ind:item[5],
                    predicted_ind:(item[6])?'Y':'N',
                    exam_ind:(item[7])?'Y':'N',
                    }
                    //console.log(this.form)
                    var findRecord=this.allQuestions.filter((item)=>{
                        return item.title.trim().toLowerCase()==this.form.title.trim().toLowerCase()
                    })[0]

                    if(findRecord!=null){
                        this.form.question_id=findRecord.question_id

                        const dte=new Date().toISOString().slice(0, 19).replace('T', ' ');
                        const param={
                                tableName:'tblReOrderParagraph',
                                idName:'question_id', 
                                id:findRecord.question_id,
                                predicted_ind:this.form.predicted_ind,
                                predicted_date:dte,
                                exam_ind:this.form.exam_ind,
                                title:this.form.title,
                                exam_date:dte,
                                type:'single', //becasue this one is  multiple choice mulitpe answers
                                url:'updatequestion/status',
                            }
                        //console.log(param)
                        this.saveQuestion(param).then(()=>{
                            var resStatus = this.answerResponse()
                            this.consoleLog.push(resStatus.message)
                            if(index<this.rowsLoadedArray.length-1){
                            this.saveRecordExcel(index+1)
                            }
                        })
                
                }
                else{
                    // if(index<this.rowsLoadedArray.length-1){
                    //         this.saveRecordExcel(index+1)
                    //         }
                    // //console.log(this.form)
                    this.saveQuestion(this.form).then(()=>{
                        var res = this.answerResponse()
                        var id=0
                        const dte=new Date().toISOString().slice(0, 19).replace('T', ' ');
                        id=res.data.insertId
                        this.consoleLog.push(res.message)
                        const param={
                            tableName:'tblReOrderParagraph',
                            idName:'question_id', 
                            id:id,
                            predicted_ind:this.form.predicted_ind,
                            predicted_date:dte,
                            exam_ind:this.form.exam_ind,
                            title:this.form.title,
                            exam_date:dte,
                            type:'multiple', //becasue this one is  multiple choice mulitpe answers
                            url:'updatequestion/status',
                        }
                        //console.log(param)
                        this.saveQuestion(param).then(()=>{
                            var resStatus = this.answerResponse()
                            this.consoleLog.push(resStatus.message)
                            if(index<this.rowsLoadedArray.length-1){
                            this.saveRecordExcel(index+1)
                            }
                        })
                    });
                
                }





                    // if(index<this.rowsLoadedArray.length-1){
                    //     this.saveRecordExcel(index+1)
                    // }
                }
                else
                {
                    //console.log('Same Value')
                    if(index<this.rowsLoadedArray.length-1){
                        this.saveRecordExcel(index+1)
                    }
                }
                
            }
            
        },
        onReset(evt) {
            evt.preventDefault()
            this.resetFrom()
        },
        resetFrom(){
            this.form={
                title:'',
                slot_time:'00:40',
                url:'',
                answers:null,
                mock_ind:'0'
            }
            this.reOrderPara=[{
                reorder_id:0,
                order_seq:0,
                reorder_desc:'',
                add:true
            }]
        },
        onSubmit(evt) {
            evt.preventDefault();
            this.form.url = 'configreorderpara/createparagraph';
            this.form.answers = this.reOrderPara;
            if(this.form.title=="")
                {
                    return vm.$alert(
                            "Please enter question title!",
                            "Warning",
                            "warning",
                            {
                                confirmButtonText: "OK"
                            })
                }
            ////console.log(this.form)
            var vm = this
            //////console.log(JSON.stringify(this.reOrderPara))
            this.saveQuestion(this.form).then(()=>{
                
                ////console.log(vm.answerResponse())
                vm.userAnswerResponse = vm.answerResponse().data
                vm.form.question_id = vm.userAnswerResponse.insertId
                ////console.log(vm.form)
                vm.allQuestions.push(vm.form)
                vm.$parent.getQuestionTypes();
                vm.resetFrom();
                vm.dismissCountDown = vm.dismissSecs
            });
            
        },
        addOption: function (ct) {
          const tempCt= {
            reorder_id:this.reOrderPara.length+1,
            order_seq:0,
            reorder_desc:'',
            add:true
          }
          ct.add = false
          this.reOrderPara.push(tempCt)
      },
      removeOption: function (index) {
          this.reOrderPara.splice(index,1)
      },
      deleteRecord(id){
          var data={
                url:'configreorderpara/deleteparagraph/id='+id
            }
            var vm = this
             this.deleteQuestion(data).then(()=>{
                vm.allQuestions = this.allQuestions.filter(function (item) {
                return item.question_id != id;
                })
                ////console.log(vm.allQuestions)
                vm.$parent.getQuestionTypes();
            });
            ////console.log(id)
      }
    },
    watch:{
        reOrderPara: {
            deep: true,
            handler () {
                this.allFilled = true//false
                // if(this.reOrderPara.title.trim() !== '' & this.reOrderPara.questionText !=='')
                // {
                //     this.allFilled = true
                // }
            }
        }
    },
    computed: {
      rows() {
          if(this.allQuestions == null){
              return 0
          }
          else{
              return this.allQuestions.length
          }
      }
    },
    beforeMount(){
        this.allQuestions = null
        this.questionservice('configreorderpara/getAllParagraphsForGrid').then(()=>{
            this.allQuestions = this.getQuestionList().data
            ////console.log(this.allQuestions)
        })
    },
}
</script>

<style scoped>
textarea {
     height: 10vh!important;
    width: 100%;
    border: 1px solid #ccc;
    resize: none;
}
label
{
    margin-top: 10px;
text-transform: capitalize;
font-weight: 500;
}
.vue-js-switch{
    margin-top: 0px!important;
}
</style>