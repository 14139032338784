<template>
    <div>
        <b-row>
            <b-col cols="10">
                    <h4 v-if="checkFlag">Please configure Reading & Write: Fill in the blanks.</h4>
                    <h4 v-else>Please select excel sheet for bulk question saving</h4>
                </b-col>
                <b-col cols="2">
                    <toggle-button :width="75" :height="28" v-model="checkFlag" :value="false" :color="{checked: '#104e94', unchecked: '#e9ecef', disabled: '#CCCCCC'}" :speed="500" :sync="true" :labels="{checked: 'enter', unchecked: 'load'}"/>
                </b-col>
            <b-form v-if="checkFlag" class="col-12"  @submit="onSubmit" @reset="onReset">
            <b-row>
                <b-col cols="6">
                    <label for="title">Enter Title</label>
                    <b-form-input v-model="fillinblank.title" placeholder="Enter Question Title"></b-form-input>
                </b-col>
                <b-col cols="6">
                    <label for="mock_ind">Select Question Type</label>
                    <b-form-select v-model="fillinblank.mock_ind" :options="dropdown"></b-form-select>
                </b-col>
            </b-row>
            <b-col cols="12">
                <div class="form-group">
                    <label for="question_para">Enter Question</label>
                    <p class="extraNote">Please replace the blank area with #blank1#, #blank2#...</p>
                    <textarea v-model="fillinblank.question_para" class="form-control" id="question_para" rows="10"></textarea>
                </div>
            </b-col>
            <b-col cols="12">
                <h5>Add Options</h5>
               <b-row  v-for="(ct,index) in options" :key="index" class="mt-4">
                    <div class="input-group col-6">
                        <input v-model="ct.option_desc" id="option_desc" type="text" class="form-control shadow-sm" name="optionText" placeholder="Option Text">
                        <b-button :disabled="ct.option_desc ==''" v-if="ct.add" variant="light" @click="addOption(ct)">
                            <b-icon shift-v="1" scale="1.1" icon="plus" style="color: #5e72e4;"></b-icon>
                            Add
                        </b-button>
                        <b-button v-else variant="outline-danger" @click="removeOption(index)">
                            <b-icon shift-v="1" scale=".8" icon="dash-circle" variant="danger"></b-icon>
                        </b-button>
                    </div>
                    <div class="col-2 mt-2">
                        <b-form-checkbox :disabled="ct.option_desc ==''" :id="'index-'+index" v-model="ct.correct_val_ind" value=T unchecked-value=F>
                        Correct 
                        </b-form-checkbox>
                    </div>
                    <div class="col-4" >
                        <b-form-select :id="'index-'+index" v-model="ct.blank_id" :options="blanks"></b-form-select>
                    </div>
                </b-row>
            </b-col>
                <div class="col-6 displayInline" align="right">
                    <b-button type="submit" variant="primary" :disabled="allFilled != true">{{buttonText}}</b-button>
                </div>
                <div class="col-6 displayInline" align="left">
                    <b-button type="reset" variant="outline-danger">Reset</b-button>
                </div>
            </b-form>
        </b-row>
        <b-row v-if="checkFlag" class="mt-4 ml-2 mr-2">
            <b-col cols="12">
                <b-alert
                :show="dismissCountDown"
                dismissible
                variant="outline-success"
                @dismissed="dismissCountDown=0"
                
                >
                Record saved successfully, dismiss in {{ dismissCountDown }} seconds...
                </b-alert>
            </b-col>
        </b-row>
        <b-row v-if="checkFlag" class="mt-4 ml-2 mr-2">
            <b-col cols="12">
                <b-table
                    id="multipleChoiceSingle"
                    :items="allQuestions"
                    :per-page="perPage"
                    :current-page="currentPage"
                    :fields="fields"
                    sort-icon-left
                    small
                    outlined
                    responsive
                    >
                    <template #cell(question_id)="data">
                    <!-- `data.value` is the value after formatted by the Formatter -->
                    <b-button variant="outline-warning" @click="deleteRecord(data.value)">
                        <b-icon icon="trash-fill"></b-icon>
                    </b-button>
                    <!-- <a href="#">{{data.value}}</a> -->
                </template>
                <template #cell(question_para)="data">
                    <!-- `data.value` is the value after formatted by the Formatter -->
                    <read-more class="readmoreDiv" more-str="read more" :text="data.value" link="#" less-str="read less" :max-chars="100"></read-more>
                    <!-- <p class="transcript">{{data.value}}</p> -->
                </template>
                </b-table>
            </b-col>
            <b-col cols="12">
                <b-pagination
                    v-model="currentPage"
                    :total-rows="rows"
                    :per-page="perPage"
                    aria-controls="multipleChoiceSingle"
                    ></b-pagination>
            </b-col>
        </b-row>
        <div v-if="!checkFlag" class="row mt-4 ml-2 mr-2">
            <div class="col-12">
                
                <div class="form-group">
                    <label for="excelFile">Select Parent Excel file</label>
                    <b-form-file
                        id="excelFile"
                        placeholder="Choose a file or drop it here..."
                        drop-placeholder="Drop file here..."
                        accept=".xlsx"
                        @change="onFileChange"></b-form-file>
                </div>
                <div class="form-group">
                    <label for="excelFile">Select Child 2 Excel file</label>
                    <b-form-file
                        id="excelChildFile"
                        placeholder="Choose a file or drop it here..."
                        drop-placeholder="Drop file here..."
                        accept=".xlsx"
                        @change="onFileChangeChild"></b-form-file>
                </div>
            </div>
            <div class="col-12 text-center">
                <b-button :disabled="!fileLoad" @click="readFile()" class="mr-4">Read Parent File</b-button>
                <b-button :disabled="!fileChildLoad" @click="readChildFile()" class="mr-4">Read Child File</b-button>
                <b-button :disabled="!readChildFileFlag" variant="primary" @click="saveRecordExcel(1)">{{saveRecord}}</b-button>
            </div>
        </div>
        <div v-if="!checkFlag" class="row mt-4 ml-2 mr-2">
            <div class="col-12">
            <ul id="console-textarea" >
                <li v-for="(item,index) in consoleLog" :key="index">
                    {{item}}
                </li>
            </ul>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions,mapGetters } from 'vuex'
import readXlsxFile from 'read-excel-file'
export default {
    data(){
        return{
            buttonText:'Save',
            allFilled: false,
             dismissSecs: 5,
            dismissCountDown: 0,
            excelFile:null,
            excelChildFile:null,
            fileLoad:false,
            fileChildLoad:false,
            checkFlag:true,
            readFileFlag:false,
            readChildFileFlag:false,
            rowsLoadedArray:null,
            rowsChildLoadedArray:null,
            saveRecord:'Save Record',
            consoleLog:['Log Info.'],
            fillinblank:{
                title:'',
                question_para:'',
                user_id:'1',
                time_slot:'00:40',
                url:'',
                answers:'',
                question_id:'',
                mock_ind:'F'
            },
            options:[{
                id:0,
                blank_id:0,
                option_desc:'',
                add: true,
                correct_val_ind:'F'
            }],
            dropdown: [
            { value: '0', text: 'Please select an option' },
            { value: 'F', text: 'General' },
            { value: 'T', text: 'Mock Test' }
            ],
            blanks:[
                {value:0,text:'select'},
                {value:1,text:'blank1'},
                {value:2,text:'blank2'},
                {value:3,text:'blank3'},
                {value:4,text:'blank4'},
                {value:5,text:'blank5'},
                {value:6,text:'blank6'},
                {value:7,text:'blank7'},
                {value:8,text:'blank8'},
                {value:9,text:'blank9'},
                {value:10,text:'blank10'}
            ],
            allQuestions:null,
            perPage: 8,
            currentPage: 1,
            fields: [
            // {
            //     key: 'edit',
            //     label:'',
            //     sortable: false
            // },
            {
                key: 'question_id',
                label:'Delete',
                sortable: true
            },
            {
                key: 'title',
                label:'Title',
                sortable: false
            },
            {
                key: 'question_para',
                label: 'Description',
                sortable: true,
            }
            ]
        }
    },
    methods:{
        ...mapActions({
            saveAnswer: 'readingmodule/saveAnswer',
            saveQuestion:'readingmodule/saveQuestion',
            questionservice: 'readingmodule/fetchQuestion',
            deleteQuestion:'readingmodule/deleteQuestion'
        }),
        ...mapGetters({
            answerResponse: 'readingmodule/AnswerResponse',
            getQuestionList: 'readingmodule/questions'
        }),
        onFileChange(e) {
            const file = e.target.files[0];
            this.excelFile=file
            this.fileLoad =true
            this.readFileFlag=false
            this.consoleLog.push('Selected file loaded.')
            // this.loadedFile = e.target.files
            // this.fileUrl = URL.createObjectURL(file);
        },
        onFileChangeChild(e){
            const file = e.target.files[0];
            this.excelChildFile=file
            this.fileChildLoad=true
            this.readChildFileFlag=false
            this.consoleLog.push('Selected Child file loaded.')
        },
        readFile(){
            if(this.excelFile!=null){
            readXlsxFile(this.excelFile).then((rows) => {
                            //console.log(rows)
                            this.consoleLog.push('Total records retrieve from excel sheet ' +rows.length +'.')
                            this.fileLoad =false
                            this.readFileFlag=true
                            this.rowsLoadedArray=rows
                        })
            }
        },
        readChildFile(){
            if(this.excelChildFile!=null){
            readXlsxFile(this.excelChildFile).then((rows) => {
                            //console.log(rows)
                            this.consoleLog.push('Total records retrieve from excel child sheet ' +rows.length +'.')
                            this.fileChildLoad =false
                            this.readChildFileFlag=true
                            this.rowsChildLoadedArray=rows
                        })
            }
        },
        saveRecordExcel(index){
            if(this.rowsLoadedArray==null | this.rowsChildLoadedArray==null)
            {
                return this.$alert('No record found')
            }
            else{
                let today = new Date().toLocaleDateString()
                var item=this.rowsLoadedArray[index]
                //console.log(item)
                //console.log(this.rowsChildLoadedArray)
                var options=[]
                this.rowsChildLoadedArray.forEach(element => {
                    if(element[1]==item[0])
                    {
                        const temp={
                            id:0,
                            blank_id:element[0],
                            option_desc:element[2],
                            add: true,
                            correct_val_ind:element[3]
                        }
                        options.push(temp)
                    }
                });
                this.fillinblank={
                    title:item[1],
                    question_para:item[2],
                    slot_time:today,
                    question_id:0,
                    user_id:'1',
                    time_slot:'',
                    url:'configfillingblanksqrw/createfillinblank',
                    answers:options,
                    mock_ind:'F',
                    new_ind:item[3],
                    predicted_ind:(item[4])?'Y':'N',
                    exam_ind:(item[5])?'Y':'N',
                }
                
                var findRecord=this.allQuestions.filter((item)=>{
                    return item.title.trim().toLowerCase()==this.fillinblank.title.trim().toLowerCase()
                })[0]
                //console.log(this.fillinblank)
                if(findRecord!=null){
                    this.fillinblank.question_id=findRecord.question_id
                    // //console.log(findRecord.question_id)
                    // //console.log(this.fillinblank)

                    // if(index<this.rowsLoadedArray.length-1){
                    //         this.saveRecordExcel(index+1)
                    //         }

                    const dte=new Date().toISOString().slice(0, 19).replace('T', ' ');
                    const param={
                            tableName:'tblrdfillinblanksq',
                            idName:'question_id', 
                            id:findRecord.question_id,
                            predicted_ind:this.fillinblank.predicted_ind,
                            predicted_date:dte,
                            exam_ind:this.fillinblank.exam_ind,
                            title:this.fillinblank.title,
                            exam_date:dte,
                            type:'single',
                            url:'updatequestion/status',
                        }
                        //console.log(param)
                        this.saveQuestion(param).then(()=>{
                            var resStatus = this.answerResponse()
                            this.consoleLog.push(resStatus.message)
                            if(index<this.rowsLoadedArray.length-1){
                            this.saveRecordExcel(index+1)
                            }
                        })
                
                }
                else{
                    // if(index<this.rowsLoadedArray.length-1){
                    //         this.saveRecordExcel(index+1)
                    //         }
                    // //console.log(this.fillinblank)
                    this.saveQuestion(this.fillinblank).then(()=>{
                        var res = this.answerResponse()
                        var id=0
                        const dte=new Date().toISOString().slice(0, 19).replace('T', ' ');
                        id=res.data.insertId
                        this.consoleLog.push(res.message)
                        const param={
                            tableName:'tblrdfillinblanksq',
                            idName:'question_id', 
                            id:id,
                            predicted_ind:this.fillinblank.predicted_ind,
                            predicted_date:dte,
                            exam_ind:this.fillinblank.exam_ind,
                            title:this.fillinblank.title,
                            exam_date:dte,
                            type:'single',
                            url:'updatequestion/status',
                        }
                        //console.log(param)
                        this.saveQuestion(param).then(()=>{
                            var resStatus = this.answerResponse()
                            this.consoleLog.push(resStatus.message)
                            if(index<this.rowsLoadedArray.length-1){
                            this.saveRecordExcel(index+1)
                            }
                        })
                    });
                
                }
            }
        },
        onReset(evt) {
            evt.preventDefault()
            this.resetFrom()
        },
        resetFrom(){
            this.fillinblank={
                title:'',
                question_para:'',
                user_id:'1',
                time_slot:'',
                url:'',
                answers:'',
                question_id:'',
                mock_ind:'0'
            }
            this.options=[{
                id:0,
                blank_id:0,
                option_desc:'',
                add: true,
                correct_val_ind:'F'
            }]
        },
        onSubmit(evt) {
            evt.preventDefault()
            ////console.log(this.options)

            this.fillinblank.url = 'configfillingblanksqrw/createfillinblank'
            this.fillinblank.answers = this.options;
            var vm=this
            if(this.fillinblank.title=="")
                {
                    return vm.$alert(
                            "Please enter question title!",
                            "Warning",
                            "warning",
                            {
                                confirmButtonText: "OK"
                            })
                }
            this.saveQuestion(this.fillinblank).then(()=>{
                ////console.log(vm.answerResponse())
                this.userAnswerResponse = this.answerResponse().data
                 vm.fillinblank.question_id = vm.userAnswerResponse.insertId
                vm.allQuestions.push( vm.fillinblank)
                vm.$parent.getQuestionTypes();
                vm.resetFrom();
                vm.dismissCountDown = vm.dismissSecs
            });
            
        },
        addOption: function (ct) {
          const tempCt= {
            id:0,//this.options.length+1,
            blank_id:0,
            option_desc:'',
            add: true,
            correct_val_ind:'F'
          }
          ct.add = false
          this.options.push(tempCt)
      },
      removeOption: function (index) {
          this.options.splice(index,1)
      },
      deleteRecord(id){
          var data={
                url:'configfillingblanksqrw/deletefillinblank/id='+id
            }
            var vm = this
             this.deleteQuestion(data).then(()=>{
                vm.allQuestions = this.allQuestions.filter(function (item) {
                return item.question_id != id;
                })
                ////console.log(vm.allQuestions)
                vm.$parent.getQuestionTypes();
            });
            ////console.log(id)
      }
    },
    watch:{
        fillinblank: {
            deep: true,
            handler () {
                this.allFilled = false
                if(this.fillinblank.title !== '' & this.fillinblank.question_para !== '')
                {
                    this.allFilled = true
                }
            }
        }
    },
    computed: {
      rows() {
          if(this.allQuestions == null){
              return 0
          }
          else{
              return this.allQuestions.length
          }
      }
    },
    beforeMount(){
        this.allQuestions = null
        this.questionservice('configfillingblanksqrw/getAllFillinBlanksForGrid').then(()=>{
            this.allQuestions = this.getQuestionList().data
            ////console.log(this.allQuestions)
        })
    },
}
</script>

<style scoped>
textarea {
     height: 30vh!important;
    width: 100%;
    border: 1px solid #ccc;
    resize: none;
}
label
{
    margin-top: 10px;
text-transform: capitalize;
font-weight: 500;
}
.vue-js-switch{
    margin-top: 0px!important;
}
</style>